import React from 'react';

import { Layout, VideoThumbnail } from '../components';

const video = {
    title: "Sparta Capital - CRH Underlying Data",
    thumbnail: {
        assetType: 'image',
        cloudinaryMedia: true,
        deliveryType: 'upload',
        sourceURL: "https://res.cloudinary.com/integrum-esg/image/upload/v1622646972/video-tutorial-thumbnails/pexels-arthouse-studio-4337996_n4cito.jpg",
        urlRemainder: "v1622646972/video-tutorial-thumbnails/pexels-arthouse-studio-4337996_n4cito.jpg"
    },
    video: {
        assetType: 'video',
        cloudinaryMedia: true,
        deliveryType: 'upload',
        sourceURL: "https://res.cloudinary.com/integrum-esg/video/upload/v1629199175/CRH_Integrum_ESG_Demo_nrl9cr.mp4",
        urlRemainder: "v1629199175/CRH_Integrum_ESG_Demo_nrl9cr.mp4"
    }
}

const SpartaCapitalPage = () => (
    <Layout title="Sparta Capital">
        <div className='py-4 lg:py-8'>
            <div className='w-full px-4 mb-4 lg:mb-8 text-2xl lg:text-4xl text-center text-primary font-light'>{ video.title }</div>
            <div className='w-full px-4 md:px-0 md:w-4/5 lg:w-3/5 mx-auto'>
                <VideoThumbnail { ...video } className='shadow-xl' size='large'/>
            </div>
        </div>
    </Layout>
);

export default SpartaCapitalPage;